.logos {
    max-width: 900px;
    margin: 15px auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
.logo {
    height: 85px;
    margin: 5px;
}

@media only screen and (max-width: 575px) {
    .logo {
        height: 55px;
    }
}
