.projects {
    max-width: 900px;
    padding: 0 20px;
    margin: 0 auto;
}
    .stats {
        display: flex;
        justify-content: space-around;
    }
        .stats-left {
            flex-direction: column;
        }
        .stat {
            max-width: 100%;
        }

@media only screen and (max-width: 860px) {
    .stats {
        flex-direction: column;
        text-align: center;
    }
}

@media only screen and (max-width: 500px) {
    .stat {
        min-width: 100%;
    }
}
