.home-hero {
    background: url('../../assets/background.jpg');
    border: 0;
    height: 55vh;
    margin: 0;
    display: flex;
    padding: 0;
    overflow: hidden;
    position: relative;
    max-height: 1000px;
    align-items: center;
    background-size: cover;
    background-position: center center;
    text-align: center;
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.headshot {
    height: 150px;
    width: 150px;
    border-radius: 100%;
}

.title {
    color: #FFFFFF;
    text-shadow: 2px 2px 8px #000;
}
  