.contact {
    max-width: 900px;
    padding: 0 20px;
    margin: 0 auto;
}

.contact {
    margin-top: 5px;
    margin-bottom: 20px;
}

.contact-form {
    max-width: 600px;
    margin: auto;
    text-align: right;
}
    .name-email-wrapper {
        display: flex;
        justify-content: space-between;
    }
        .name-email {
            width: 45%;
        }

    .alert-message {
        max-width: 600px;
        margin: auto;
    }

@media only screen and (max-width: 550px) {
    .contact-form {
        width: 100%;
    }
        .name-email-wrapper {
            flex-direction: column;
        }
            .name-email {
                width: 100%;
            }
}